// What year is this??
import $ from 'jquery'
import 'slick-carousel'

$(function() {
  const slickBaseOpts = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
  }

  $('.homepage-redux .hero-redux__carousel .carousel').each(function() {
    $(this).slick({
      ...slickBaseOpts,
      arrows: true,
      prevArrow: $(this).siblings('.carousel__control.carousel__control--prev'),
      nextArrow: $(this).siblings('.carousel__control.carousel__control--next'),
    });
  });


  $(document).ready(function() {
    var lazyVideos = $("video.lazy");

    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            $(video.target).find("source").each(function() {
              $(this).attr("src", $(this).data("src"));
            });

            video.target.load();
            $(video.target).removeClass("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.each(function() {
        lazyVideoObserver.observe(this);
      });
    }
  });
})
